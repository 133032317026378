import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s2 from './Component/s2.png';

function Contact() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4 container'>
        <span style={{ fontSize: '35px' }}>Contact us</span> <br />
        <small className='justify'>GET IN TOUCH WITH THE FLOORING PROFESSIONALS AT OLIVER FLOORING</small>
      </div>
      <div className='row '>
        <div className='col-md-8'><Request /> </div>
        <div className='col-md-3 p-4'>
          <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
              <h2 className="text-2xl font-bold ">Store Info</h2>
              <p className="text-gray-600 text-2xl ">Address</p>
              <p className="text-gray-800">
              2009 South Shady St Mountain City, TN 37683       </p>
              <a href="https://www.google.com/maps/place/Oliver+Flooring/@36.4495553,-81.7996252,17z/data=!4m13!1m7!3m6!1s0x88505c66e4968f01:0x146d5759374fbb0d!2s2009+S+Shady+St,+Mountain+City,+TN+37683!3b1!8m2!3d36.4495553!4d-81.7974365!3m4!1s0x88505d9e2bf2603f:0x8d2df70de7a3a77c!8m2!3d36.4495552!4d-81.7974365" target="_blank" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} rel="noreferrer">Get directions</a>

            </div>


            <div>
              <p className="text-gray-600">Phone</p>
              <p className="text-gray-800">(828) 406-1417</p>
            </div>

            <div className="mb-4">
              <a href="tel:+18284061417" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} >Call us</a>

            </div>

            <div className="mb-4">
              <p className="text-gray-600">Hours</p>
              <p className="text-gray-800">
              Mon-Thu 1pm - 5pm Friday by<br />
              appointment only Sat-Sun Closed
              </p>
            </div>

            <div>
              <a href="/free-estimate" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{  borderRadius: '15px' }} >Request</a>

            </div>
          </div>
        </div>

      </div>
      <div class="container py-5">
        <a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

      </div>
      <div class="container">
        <div class="map-responsive">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3209.333559407383!2d-81.7979434!3d36.4494945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88505d9e2bf2603f%3A0x8d2df70de7a3a77c!2sOliver%20Flooring!5e0!3m2!1sen!2sin!4v1718717700244!5m2!1sen!2sin" title="example" width="100%" height="450" frameborder="0"  allowfullscreen />
        </div>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Contact
